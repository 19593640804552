<script>
export default {
  name: "PageHeading"
}
</script>

<template>
  <h2 class="hs-line-7 mb-20 mb-xs-10">
    <slot></slot>
  </h2>
</template>

<style scoped>

</style>