<template>
  <div :class="{
    'card bg-light mb-4': true,
    'card--collapse': collapse,
    'card--collapsed': collapse && !isOpen,
  }">
    <div v-if="$slots.heading"
         class="card-header d-flex gap-3 justify-content-between align-items-center text-light"
         @click="handleClick">
      <div class="card-header-inner">
        <slot name="heading"/>
      </div>
      <div v-if="collapse" class="card-toggle">
        <i v-if="isOpen" class="fas fa-arrow-circle-up"></i>
        <i v-else class="fas fa-arrow-circle-down"></i>
      </div>
    </div>
    <div class="card-collapse-outer">
      <div class="card-collapse">
        <div class="card-body">
          <slot/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    collapse: {
      type: Boolean,
      default: false
    },
    collapsed: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      isOpen: !this.collapse || !this.collapsed
    }
  },
  methods: {
    handleClick() {
      if (!this.collapse) {
        return;
      }

      this.isOpen = !this.isOpen;
    }
  }
}
</script>

<style scoped>
.card-header {
  background-color: #1D292A;
}

.card-toggle {
  font-size: 1.5rem;
  color: #CCFF3A;
}

.card--collapse .card-header {
  cursor: pointer;
}

.card-collapse {
  overflow: hidden;
}

.card-collapse-outer {
  display: grid;
  grid-template-rows: 1fr;
  transition: grid-template-rows 200ms ease-in-out;
}

.card--collapsed .card-collapse-outer {
  grid-template-rows: 0fr;
}
</style>