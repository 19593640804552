import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import firebase from 'firebase/compat/app'
import PageSection from '@/components/PageSection.vue'
import PageHero from "@/components/PageHero.vue";
import AnimateIn from "@/components/AnimateIn.vue";
import TwoColumns from "@/components/TwoColumns.vue";
import PageSubHeading from "@/components/PageSubheading.vue";
import PageHeading from "@/components/PageHeading.vue";
import TextButton from "@/components/TextButton.vue";
import AnchorLink from "@/components/AnchorLink.vue";
import Rule from "@/components/Rule.vue";
import ThreeColumns from "@/components/ThreeColumns.vue";
import Button from "@/components/Button.vue";
import ResponsiveEmbed from "@/components/ResponsiveEmbed.vue";
import Card from "@/components/Card.vue";

// firebase config for digibrass
const firebaseConfig = {
    apiKey: "AIzaSyBv-IoE2WX-PMsno6b_-g101lY1DZViLZQ",
    authDomain: "tiltwebsite.firebaseapp.com",
    databaseURL: "https://tiltwebsite-default-rtdb.firebaseio.com",
    projectId: "tiltwebsite",
    storageBucket: "tiltwebsite.appspot.com",
    messagingSenderId: "288880490265",
    appId: "1:288880490265:web:57cd2d265adfead2873788",
    measurementId: "G-HE86SXLJWB"
};

firebase.initializeApp(firebaseConfig);
//console.log(firebase);

//Initialize Components
const app = createApp(App);

app.component('animate-in', AnimateIn);
app.component('app-button', Button)
app.component('page-section', PageSection);
app.component('page-hero', PageHero);
app.component('two-columns', TwoColumns);
app.component('page-subheading', PageSubHeading);
app.component('page-heading', PageHeading);
app.component('text-button', TextButton);
app.component('anchor-link', AnchorLink);
app.component("rule", Rule);
app.component("three-columns", ThreeColumns);
app.component("responsive-embed", ResponsiveEmbed);
app.component("card", Card);

app.use(router).mount('#app');
