<script>
export default {
  name: "PageSubheading"
}
</script>

<template>
  <p class="hs-line-6 opacity-075 mb-20 mb-xs-0">
    <slot></slot>
  </p>
</template>

<style scoped>

</style>