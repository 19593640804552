<template>
  <div :style="{
    '--embed-padding': (aspectRatio.split(':')[1] / aspectRatio.split(':')[0]) * 100 + '%'
  }" class="embed">
    <slot/>
  </div>
</template>

<script>
export default {
  name: "Embed",
  props: {
    aspectRatio: {
      type: String,
      default: "16:9"
    },
  },
  mounted() {

  }
};
</script>

<style>
.embed {
  position: relative;
  width: 100%;
  padding-bottom: var(--embed-padding);
  height: 0;
  overflow: hidden;
  max-width: 100%;
}

.embed iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>