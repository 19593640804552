<script>
export default {
  name: "Rule"
}
</script>

<template>
  <hr class="mt-0 mb-0 white "/>
</template>

<style scoped>

</style>