<script>
export default {
  name: "ThreeColumns"
}
</script>

<template>
  <div class="row">
    <div class="col-md-4 mb-xs-30">
      <slot name="left"></slot>
    </div>
    <div class="col-md-4 mb-xs-30">
      <slot name="middle"></slot>
    </div>
    <div class="col-md-4 mb-xs-30">
      <slot name="right"></slot>
    </div>
  </div>
</template>

<style scoped>

</style>