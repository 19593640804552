<script>
export default {
  name: "AnimateIn",
  props: {
    delay: {
      type: String,
      default: ".1s"
    },
    duration: {
      default: false
    },
    effect: {
      type: String,
      default: "fadeInUpShort"
    }
  }
}
</script>

<template>
  <div :class="{
    'wow': true,
    [effect]: true
  }"
       :data-wow-delay="delay"
       :data-wow-duration="duration">
    <slot></slot>
  </div>
</template>

<style scoped>

</style>