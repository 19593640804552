<script>
export default {
  name: "PageSection",
  props: {
    label: {
      default: function () {
        return false
      }
    },
    backgroundImage: {
      default: function () {
        return false
      }
    },
    type: {
      type: String,
      default: ""
    },
    container: {
      type: Boolean,
      default: true
    },
    containerClassName: {
      type: String,
      default: ""
    },
    paddingTop: {
      default: "default"
    },
    paddingBottom: {
      default: "default"
    },
    theme: {
      type: String,
      default: "dark"
    },
    heading: {
      type: String,
      default: ""
    },
    headingAlignment: {
      type: String,
      default: "left"
    }
  },
  computed: {
    className() {
      let typeClass = "page-section"
      if (this.type) {
        typeClass = `${typeClass}-${this.type}`
      }
      return {
        [typeClass]: true,
        "pb-140 p-sm-70": this.paddingBottom === "default",
        "pb-70 p-sm-30": this.paddingBottom === "medium",
        "pb-30 p-sm-18": this.paddingBottom === "short",
        "pt-30": this.paddingTop === "short",
        "pt-70": this.paddingTop === "medium",
        "pt-140": this.paddingTop === "tall",
        "bg-dark light-content": this.theme === "dark",
        "bg-dark bg-dark-alfa-70 light-content": this.theme === "black",
        "bg-dbdark light-content": this.theme === "green",
        'bg-dbdark-lighter light-content': this.theme === 'light-green',
        'bg-light dark-content': this.theme === 'light',
        'bg-color-dblight dark-content': this.theme === 'gray'
      }
    },
    style() {
      let style = {}
      if (this.backgroundImage) {
        let background = this.backgroundImage
        //If it's a plain URL, add url() around it
        if (!background.includes("url(") && !background.includes(" ")) {
          background = `url(${background})`
        }
        style = {
          ...style,
          backgroundImage: this.backgroundImage,
        }
      }
      return style
    }
  }
}
</script>

<template>
  <section :aria-label="label"
           :class="className"
           :style="style"
  >
    <div v-if="container" :class="{
      'container': true,
      [containerClassName]: !!containerClassName
    }">
      <div v-if="heading" :class="`text-${headingAlignment}`">
        <h2 class="section-subtitle" v-text="heading"/>
      </div>
      <slot></slot>
    </div>
    <template v-else>
      <h2 v-if="heading" class="section-subtitle" v-text="heading"/>
      <slot></slot>
    </template>
  </section>
</template>

<style scoped>

</style>