<script>
import AnimateIn from "@/components/AnimateIn.vue";

export default {
  name: "VideoGallery",
  components: {AnimateIn},
  props: {
    videos: {
      type: Array,
      default: () => []
    },
  }
}
</script>

<template>
  <ul class="works-grid work-grid-4 work-grid-gut clearfix hover-white hide-titles">
    <li v-for="({url, title, description, image}, idx) in videos" :key="idx"
        class="work-item mix category-1 category-2">
      <a :href="url" class="work-lightbox-link mfp-iframe">
        <div class="work-img">
          <div class="work-img-bg wow-p scalexIn"></div>
          <AnimateIn :delay="`${idx * 100}ms`">
            <img :src="image" alt="Video 1 Thumbnail" class="wow-p fadeIn" data-wow-delay=".3ms">
          </AnimateIn>
        </div>
        <div class="work-intro">
          <h3 class="work-title" v-text="title"/>
          <div class="work-descr" v-text="description"/>
        </div>
      </a>
    </li>
  </ul>
</template>

<style scoped>

</style>