<script>
export default {
  name: "TwoColumns"
}
</script>

<template>
  <div class="section-text row">
    <div class="col-md-6 col-lg-4 mb-sm-50 mb-xs-30">
      <slot name="left"></slot>
    </div>
    <div class="col-md-4 col-lg-4 mb-sm-50 mb-xs-30">
      <slot name="right"></slot>
    </div>
  </div>
</template>

<style scoped>

</style>