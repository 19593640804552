import { createRouter, createWebHistory } from 'vue-router'
import Index from '../views/Index.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: Index
  },
//   {
//     path: '/login',
//     name: 'login',
//     // route level code-splitting
//     // this generates a separate chunk (about.[hash].js) for this route
//     // which is lazy-loaded when the route is visited.
//     component: () => import(/* webpackChunkName: "about" */ '../views/Login.vue')
//   },
//   {
//     path: '/register',
//     name: 'register',
//     component: () => import('../views/Register.vue')
//   },
//   {
//     path: '/user-page',
//     name: 'user-page',
//     component: () => import('../views/UserPage.vue')
//   },
  {
    path: '/Support',
    name: 'Support',
    component: () => import('../views/Support.vue')
  },
  {
    path: '/Tilt',
    name: 'Tilt',
    component: () => import('../views/Tilt.vue')
  },
  {
    path: '/Shop',
    name: 'Shop',
    component: () => import('../views/Shop.vue')
  },
  {
    path: '/About',
    name: 'About',
    component: () => import('../views/About.vue')
  },
  {
    path: '/FAQ',
    name: 'FAQ',
    component: () => import('../views/FAQ.vue')
  },
  {
    path: '/ReturnsAndWarranty',
    name: 'ReturnsAndWarranty',
    component: () => import('../views/ReturnsAndWarranty.vue')
  },
  {
    path: '/Documentation',
    name: 'Documentation',
    component: () => import('../views/Documentation.vue')
  },
  {
    path: '/Legal',
    name: 'Legal',
    component: () => import('../views/Legal.vue')
  },
  {
    path: '/Learn',
    name: 'Learn',
    component: () => import('../views/Learn.vue')
  },
  {
    path: '/SupportTicket',
    name: 'SupportTicket',
    component: () => import('../views/SupportTicket.vue')
  },
  {
    path: '/PrivacyPolicy',
    name: 'PrivacyPolicy',
    component: () => import('../views/PrivacyPolicy.vue')
  },
  {
    path: '/QuickStart',
    name: 'QuickStart',
    component: () => import('../views/QuickStart.vue')
  },
  {
    path: '/FirstChair',
    name: 'FirstChair',
    component: () => import('../views/FirstChair.vue')
  },
  {
    path: '/Videos',
    name: 'Videos',
    component: () => import('../views/Videos.vue')
  },
  {
    path: '/SynthOne',
    name: 'SynthOne',
    component: () => import('../views/SynthOne.vue')
  },
  {
    path: '/ResourceList',
    name: 'ResourceList',
    component: () => import('../views/ResourceList.vue')
  },
  {
    path: '/2024_08_27',
    name: '2024_08_27',
    component: () => import('../views/2024_08_27.vue')
  },
  {
    path: '/Playback',
    name: 'Playback',
    component: () => import('../views/Playback.vue')
  },

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
