<template>
  <page-section id="contact" heading="Contact DigiBrass" heading-alignment="center">
    <div class="row">
      <div class="col-md-10 offset-md-1">
        <form id="contact_form" class="form contact-form" @submit.prevent="sendToEmail($event)">
          <div class="row">
            <div v-if="errorMessageContact" class="error-message">{{ errorMessageContact }}</div>
            <!-- <div class="success-message" v-if="successMessageContact">{{successMessageContact}}</div> -->
            <transition mode="out-in" name="fade">
              <div v-if="successMessageContact" class="alert" role="alert">
                {{ successMessageContact }}
              </div>
            </transition>
            <div class="col-md-6">
              <!-- Name -->
              <div class="form-group">
                <label for="name">Name</label>
                <input id="name" v-model="name" aria-required="true" class="input-lg round form-control" name="name"
                       pattern=".{3,100}" placeholder="Enter your name" required type="text">
              </div>
            </div>
            <div class="col-md-6">
              <!-- Email -->
              <div class="form-group">
                <label for="email">Email</label>
                <input id="email" v-model="email" aria-required="true" class="input-lg round form-control"
                       name="email"
                       pattern=".{5,100}" placeholder="Enter your email" required type="email">
              </div>
            </div>
          </div>
          <!-- Message -->
          <div class="form-group">
            <label for="message">Message</label>
            <textarea id="message" v-model="message" class="input-lg round form-control" name="message"
                      placeholder="Enter your message" style="height: 130px;"></textarea>
          </div>
          <div class="row">
            <div class="col-sm-6">
              <!-- Inform Tip -->
              <div class="form-tip pt-20 pt-sm-0 mb-sm-20">
                All the fields are required
              </div>
            </div>
            <div class="col-sm-6">
              <!-- Send Button -->
              <div class="text-end pt-10">
                <button id="submit_btn" aria-controls="result"
                        class="submit_btn btn btn-mod btn-color btn-large btn-round"
                        type="submit">
                  Submit Message
                </button>
              </div>
            </div>
          </div>
          <div id="result" aria-atomic="true" aria-live="polite" role="region"></div>
        </form>
      </div>
    </div>
  </page-section>
</template>
<script>
import emailjs from "emailjs-com";

export default {
  data() {
    return {
      name: '',
      email: '',
      message: '',
      successMessage: '',
      errorMessage: '',
      successMessageContact: '',
      errorMessageContact: ''
    }
  },
  methods: {
    sendToEmail(e) {
      try {
        emailjs.sendForm('service_9si27th', 'template_8nuvp3q', e.target,
            'EzK_VvuTA9F0w4e03', {
              name: this.name,
              email: this.email,
              message: this.message
            })
        this.successMessageContact = "Your message is sent, thank you!";
      } catch (error) {
        this.errorMessageContact = "Sorry, something went wrong.";
      }

      setTimeout(() => {
            this.successMessageContact = "";
            this.errorMessageContact = "";
          },
          5000
      );

      // Reset form field
      this.name = ''
      this.email = ''
      this.message = ''
    }
  }
}
</script>