<script>
export default {
  name: "TextButton",
  props: {
    icon: {
      default: false
    },
    label: {
      type: String,
      required: true
    }
  }
}
</script>

<template>
  <a class="text-link">
    <span v-text="label"/>
    <i class="fas fa-arrow-circle-down"></i>
  </a>
</template>

<style scoped>
.text-link {
  display: inline-flex;
  gap: .75em;
  align-items: center;
}
</style>