<script>
export default {
  name: "AnchorLink",
  props: {
    to: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: true
    }
  },
  computed: {
    href() {
      return `#${this.to}`
    }
  }
}
</script>

<template>
  <text-button
      :href="href"
      :label="label"
      icon="fas fa-arrow-circle-down"
      target="_self"
  ></text-button>
</template>

<style scoped>

</style>