<template>
    <page-section id="stylesIntro"
        heading="Welcome to DigiBrass"
        heading-alignment="center"
        padding-bottom="medium"
        theme="green">
  
        <div class="hs-line-14 home-content">
            We’ve expanded the universe of trumpet playing.<br>
            Sounds big, right?<br><br>
            With this digital instrument, you can do more than you've ever dreamed of with your analog horn.<br><br>
            It’s the same as a trumpet… but different… <br><br>
        </div>
        <div class="hs-line-14 home-content">
            <b>Check out this short introduction:</b>
        </div>    
           
        <div class="vimeo-responsive">
            <iframe 
              src="https://player.vimeo.com/video/967246134?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" 
              frameborder="0" 
              allow="autoplay; fullscreen; picture-in-picture" 
              allowfullscreen>    
            </iframe>
        </div>
    </page-section>
  
    <rule />
  
    <page-section id="part2"
        heading="Who is the Tilt for?"
        heading-alignment="center"
        padding-bottom="medium"
        theme="green">
  
        <div class="hs-line-14 home-content">
            The Tilt by DigiBrass is a revolutionary digital instrument designed for trumpeters and other valved brass players.<br><br>
            It opens up new possibilities for anyone interested in exploring digital soundscapes and expanded musical possibilities.<br><br>
            Check out these different styles and sounds played on the Tilt.
        </div>       
  
    </page-section>
  
    <page-section
        id="videos"
        heading=""
        heading-alignment="center"
        padding-bottom="medium"
        theme="green">
        <VideoGallery :videos="[
            {
              url: 'https://vimeo.com/797536678',
              title: 'Legit Brass',
              description: 'Playing legit music on the Tilt',
              image: 'images/MouretRondeau.jpg'
            },
            {
              url: 'https://vimeo.com/885971947',
              title: 'Jazz Standards',
              description: 'Playing Jazz Live',
              image: 'images/YouDontKnowWhatLoveIs.jpg'
            },
            {
            url: 'https://vimeo.com/686743084',
            title: 'Rock',
            description: 'Surprise your audience!',
            image: 'images/Chameleon.jpg'
            },
            {
              url: 'https://vimeo.com/806832653',
              title: 'Techno',
              description: 'Flexible, expressive play!',
              image: 'images/OmensOfLove.jpg'
            }
        ]"/>
    </page-section>
  </template>
  
  <script>
  import VideoGallery from "@/components/VideoGallery.vue";
  
  export default {
    components: {
      VideoGallery,
    }
  }
  </script>
  
  <style scoped>
  /* Custom horizontal rule style */
  .custom-hr {
    height: 2px;            /* Adjust the thickness */
    background-color: white; /* Color of the line */
    width: 100%;             /* Full width */
    margin: 20px 0;          /* Spacing around the line */
  }
  
  /* Make the Vimeo container responsive */
  .vimeo-responsive {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 aspect ratio */
    height: 0;
    overflow: hidden;
    max-width: 100%;        /* Ensures the container doesn't overflow */
  }
  
  .vimeo-responsive iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;               /* Optional border removal */
  }
  
  .hs-line-14 {
    font-size: 1.5rem;
    text-align: center;       /* Center the text */
    line-height: 1.5;
  }
  
  @media (max-width: 768px) {
    .hs-line-14 {
      font-size: 1.2rem;     /* Adjust font size for smaller screens */
    }
  }
  </style>
  