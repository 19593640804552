<template>
  <page-section id="newsletter" theme="black">
    <div class="container relative">
      <form id="mailchimp" class="form wow fadeInUpShort">
        <div class="row">
          <div class="col-md-8 offset-md-2">
            <div class="newsletter-label d-flex mb-50 mb-sm-20">
              <div class="newsletter-label-icon">
                <svg aria-hidden="true" clip-rule="evenodd" fill="currentColor" fill-rule="evenodd" focusable="false"
                     height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
                  <path
                      d="M1.999 1v7.269l-2 1.456v13.275h24.001v-13.275l-2.001-1.453v-7.272h-20zm1 11.234v-10.234h18v10.233l-9 5.766-9-5.765zm19 .54v-3.17l1.001.764v11.632h-22.001v-11.641l1-.707.063 3.134 9.937 6.413 10-6.425zm-14.305-6.752l-2.694.496 1.888 1.986-.361 2.716 2.472-1.182 2.473 1.182-.361-2.716 1.888-1.986-2.695-.496-1.305-2.41-1.305 2.41zm.248 2.139l-.945-.994 1.349-.249.653-1.206.654 1.206 1.349.249-.945.994.18 1.36-1.238-.591-1.238.591.181-1.36zm6.058-3.078h4.999v-1h-4.999v1zm0 2h4.999v-1h-4.999v1zm0 2h4.999v-1h-4.999v1zm0 2h3v-1h-3v1z"/>
                </svg>
              </div>
              <h2 class="newsletter-label-text">
                Sign up for&nbsp;our email list
              </h2>
            </div>
            <div class="d-sm-flex justify-content-between mb-20 mt-5">
              <input aria-required="true" class="newsletter-field input-lg round" pattern=".{5,100}"
                     placeholder="Your Email" required type="email">
              <button aria-controls="subscribe-result"
                      class="newsletter-button btn btn-mod btn-color btn-large btn-round"
                      type="submit">
                Subscribe Now
              </button>
            </div>
            <div class="form-tip">
              Don't worry, we won't share your email address or spam you.
            </div>
            <div id="subscribe-result" aria-atomic="true" aria-live="polite" role="region"></div>
          </div>
        </div>
      </form>
    </div>
  </page-section>
</template>

<style scoped>

</style>

<script>

export default {
  name: 'EmailSignUp',
  mounted() {
    const script = document.createElement("script");
    script.setAttribute("src", "js/jquery.ajaxchimp.min.js");
    document.head.appendChild(script);
  }
}
</script>
