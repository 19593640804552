<script>
export default {
  name: "IntroSection",
}
</script>

<template>
  <page-section id="about" ref="about" theme="green">
    <!-- First Row: Learn More -->
    <div class="row section-text align-items-center mb-4">
      <div class="col-12 col-md-12 col-lg-4 mb-3 text-center text-lg-left">
        <div class="lead-alt wow linesAnimIn" data-splitting="lines">
          Want to learn more?
        </div>
      </div>

      <div class="col-12 col-md-5 col-lg-3 mb-3 d-none d-md-block">
        <!-- Empty Space for alignment -->
      </div>

      <div class="col-12 col-md-7 col-lg-4 mb-3 text-center text-lg-left">
        <div class="local-scroll wow fadeInUpShort">
          <a class="btn btn-mod btn-color btn-med btn-round me-md-1" href="/Tilt">
            Visit the Tilt page
          </a>
        </div>
      </div>
    </div>

    <!-- Second Row: Stay in Touch -->
    <div class="row section-text align-items-center mb-4">
      <div class="col-12 col-md-12 col-lg-4 mb-3 text-center text-lg-left">
        <div class="lead-alt wow linesAnimIn" data-splitting="lines">
          Want to stay in touch?
        </div>
      </div>

      <div class="col-12 col-md-5 col-lg-3 mb-3 d-none d-md-block">
        <!-- Empty Space for alignment -->
      </div>

      <div class="col-12 col-md-7 col-lg-4 mb-3 text-center text-lg-left">
        <div class="local-scroll wow fadeInUpShort">
          <a class="btn btn-mod btn-color btn-med btn-round me-md-1" href="#newsletter">
            Subscribe to our newsletter
          </a>
        </div>
      </div>
    </div>

    <!-- Third Row: Contact Us -->
    <div class="row section-text align-items-center mb-4">
      <div class="col-12 col-md-12 col-lg-4 mb-3 text-center text-lg-left">
        <div class="lead-alt wow linesAnimIn" data-splitting="lines">
          Want to contact us now?
        </div>
      </div>

      <div class="col-12 col-md-5 col-lg-3 mb-3 d-none d-md-block">
        <!-- Empty Space for alignment -->
      </div>

      <div class="col-12 col-md-7 col-lg-4 mb-3 text-center text-lg-left">
        <div class="local-scroll wow fadeInUpShort">
          <a class="btn btn-mod btn-color btn-med btn-round me-md-1" href="#contact">
            Send a message to the team
          </a>
        </div>
      </div>
    </div>
  </page-section>
</template>

<style scoped>
/* Section Spacing */
.section-text {
  padding: 20px 0;
}

.lead-alt {
  font-size: 1.75rem;
  font-weight: bold;
}

.btn-mod {
  margin-top: 10px;
}

@media (max-width: 768px) {
  /* Adjust font size and layout for small devices */
  .lead-alt {
    font-size: 1.4rem;
  }
}
</style>
