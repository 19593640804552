<script>
export default {
  name: "pageHero",
  props: {
    type: {
      default: "circuit"
    }
  }
}
</script>

<template>
  <page-section
      :type="type"
      class="bg-dark-alfa-50 bg-scroll"
      container-class-name="pt-140 pb-140"
      padding-bottom="false"
      padding-top="false"
      theme="custom"
  >
    <div class="row">
      <div class="col-md-8">
        <slot></slot>
      </div>
    </div>
  </page-section>
</template>

<style scoped>

</style>