<template>
    <!-- Skip to Content -->
    <a href="#main" class="btn skip-to-content">Skip to Content</a>
    <!-- End Skip to Content -->
  
    <!-- Page Wrap -->
    <div class="page bg-dark light-content" id="top">
      <!-- Navigation panel -->
      <nav class="main-nav dark transparent stick-fixed wow-menubar">
        <div class="full-wrapper relative clearfix">
          <!-- Logo -->
          <div class="nav-logo-wrap local-scroll">
            <a href="/#home" class="logo">
              <img src="images/logo-white.png" alt="DigiBrass logo" width="188" height="37" />
            </a>
          </div>
  
          <!-- Mobile Menu Button -->
          <div class="mobile-nav" role="button" tabindex="0">
            <i class="fa fa-bars"></i>
            <span class="sr-only">Menu</span>
          </div>
  
          <!-- Main Menu -->
          <div class="inner-nav desktop-nav">
            <ul class="clearlist scroll-nav local-scroll">
              <li><a href="/Tilt">The Tilt</a></li>
              <li><a href="https://digibrass.myshopify.com">Shop</a></li>
              <li><a href='/Videos'>Videos</a></li>
  
              <!-- Item With Submenu: What's the Tea? -->
              <li>
                <a href="#" class="mn-has-sub">What's the Tea? <i class="mn-has-sub-icon"></i></a>
                <ul class="mn-sub to-right">
                  <li><a href="/2024_08_27">August 2024 Update</a></li>
                </ul>
              </li>
  
              <!-- Item With Submenu: Support -->
              <li>
                <a href="#" class="mn-has-sub">Support <i class="mn-has-sub-icon"></i></a>
                <ul class="mn-sub to-right">
                  <li><a href="https://Learn.DigiBrass.com">Learn.DigiBrass.com</a></li>
                  <li><a href="/ReturnsAndWarranty">Returns and Warranty</a></li>
                  <li><a href="/SupportTicket">Submit a Support Ticket</a></li>
                  <li><a href="/ResourceList">Other Resources</a></li>
                </ul>
              </li>
  
              <li><a href='/#newsletter'>Signup</a></li>
  
              <!-- Item With Submenu: Contact -->
              <li>
                <a href="#" class="mn-has-sub">Contact <i class="mn-has-sub-icon"></i></a>
                <ul class="mn-sub to-left">
                  <li><a href="/#contact">Contact Us</a></li>
                  <li><a href="/#newsletter">Email List Sign Up</a></li>
                </ul>
              </li>
  
              <li><a href="https://www.facebook.com/digibrass" target="_blank"><i class="fab fa-facebook-f"></i></a></li>
              <li><a href="https://www.instagram.com/digibrass" target="_blank"><i class="fab fa-instagram"></i></a></li>
            </ul>
          </div>
          <!-- End Main Menu -->
        </div>
      </nav>
      <!-- End Navigation panel -->
    </div>
  
    <!-- Vue Router View -->
    <router-view />
  
    <!-- Footer -->
    <footer class="page-section bg-dark-lighter light-content footer pb-100 pb-sm-50">
      <div class="container">
        <div class="row">
          <!-- Company Links -->
          <div class="col-sm-4 mb-40">
            <div class="footerlink">
              <h5>Company</h5>
              <ul><a href="/login">Admin Login</a></ul>
            </div>
          </div>
  
          <!-- Information Links -->
          <div class="col-sm-4 mb-40">
            <div class="footerlink">
              <h5>Information</h5>
              <ul><a href="/ReturnsAndWarranty" target="_self">Returns and Warranty</a></ul>
              <ul><a href="/QuickStart" target="_self">Quick Start Guide</a></ul>
              <ul><a href="/Documentation" target="_self">Documentation</a></ul>
              <ul><a href="/PrivacyPolicy" target="_self">Privacy Policy</a></ul>
              <ul><a href="/Legal" target="_self">Legal</a></ul>
            </div>
          </div>
  
          <!-- Social Links -->
          <div class="col-sm-4 mb-40">
            <div class="footerlink">
              <h5>Social</h5>
              <ul><a href="https://www.facebook.com/digibrass/" target="_blank">Facebook</a></ul>
              <ul><a href="https://www.instagram.com/digibrass/" target="_blank">Instagram</a></ul>
              <ul><a href="https://www.vimeo.com/digibrass/" target="_blank">Vimeo</a></ul>
            </div>
          </div>
        </div>
      </div>
  
      <div class="container">
        <!-- Footer Social Links -->
        <div class="footer-social-links mb-90 mb-xs-40">
          <a href="https://www.facebook.com/digibrass/" target="_blank"><i class="fab fa-facebook-f"></i> <span class="sr-only">Facebook</span></a>
          <a href="https://www.instagram.com/digibrass/" target="_blank"><i class="fab fa-instagram"></i> <span class="sr-only">Instagram</span></a>
          <a href="https://www.vimeo.com/digibrass/" target="_blank"><i class="fab fa-vimeo"></i> <span class="sr-only">Vimeo</span></a>
        </div>
  
        <!-- Footer Text -->
        <div class="footer-text">
          <div class="footer-copy">
            <a href="index.html">© DigiBrass LLC 2023</a>
            <p>Music innovation from the Northwoods of Wisconsin</p>
          </div>
        </div>
      </div>
  
      <!-- Top Link -->
      <div class="local-scroll">
        <a href="#top" class="link-to-top"><i class="link-to-top-icon"></i></a>
      </div>
    </footer>
    <!-- End Footer -->
  </template>
  
  <script>
  import { signOut } from '@firebase/auth';
  import { getAuth, onAuthStateChanged } from "firebase/auth";
  import { getAnalytics, logEvent } from 'firebase/analytics';
  
  export default {
    name: 'App',
    data() {
      return {
        userOn: null,
        userOff: true,
        requestUser: null,
      }
    },
    created() {
      const auth = getAuth();
      this.userOn = onAuthStateChanged(auth, (user) => {
        if (user) {
          this.userOn = user.email;
        }
      });
      this.setRequestUser();
    },
    mounted() {
      const script = document.createElement("script");
      script.setAttribute("src", "js/all.js");
      document.head.appendChild(script);
  
      const analytics = getAnalytics();
      logEvent(analytics, 'Homepage viewed');
    },
    methods: {
      logout() {
        const auth = getAuth();
        signOut(auth).then(() => {
          window.location.href = '/';
          var username = window.localStorage.getItem("username");
          window.localStorage.removeItem("username", username);
        });
      },
      setRequestUser() {
        this.requestUser = window.localStorage.getItem("username");
      }
    }
  }
  </script>
  
  <style>
  #app {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: left;
    margin-top: 60px;
  }
  </style>
  