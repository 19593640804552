<template>
  <a :class="classNames">
    <slot/>
  </a>
</template>

<script>
export default {
  props: {
    theme: {
      type: String,
      default: 'default'
    }
  },
  computed: {
    classNames() {
      return {
        'btn btn btn-mod btn-color btn-large btn-round': this.theme === 'large',
        'btn btn-mod btn-color btn-medium btn-round': this.theme === 'default'
      }
    }
  }
}
</script>